import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import Check from "@material-ui/icons/Check";

import styles from "assets/jss/material-kit-react/customCheckboxRadioSwitch.jsx";

class CarFormCheckbox extends React.Component {
    render() {
        const { classes } = this.props;
        const wrapperDiv = classNames(
            classes.checkboxAndRadio,
            classes.checkboxAndRadioHorizontal
        );
        return (
            <div>
                <div className={wrapperDiv}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                tabIndex={-1}
                                onClick={this.props.onClick}
                                checked={this.props.checked}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{ checked: classes.checked }}
                            />
                        }
                        classes={{ label: classes.label }}
                        label={this.props.title}
                    />
                </div>
            </div>
        );
    }
}

CarFormCheckbox.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    onClick: PropTypes.func,
    checked: PropTypes.bool
};

export default withStyles(styles)(CarFormCheckbox);