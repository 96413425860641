import React from "react";
import { withFirebase } from "components/Firebase";
import FileUploader from "react-firebase-file-uploader";
import { fixRotation } from "fix-image-rotation";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Admin/Grid/GridItem.jsx";
import GridContainer from "components/Admin/Grid/GridContainer.jsx";
import CustomInput from "components/Admin/CustomInput/CustomInput.jsx";
import Button from "components/Admin/CustomButtons/Button.jsx";
import Card from "components/Admin/Card/Card.jsx";
import CardHeader from "components/Admin/Card/CardHeader.jsx";
import CardBody from "components/Admin/Card/CardBody.jsx";
import CardFooter from "components/Admin/Card/CardFooter.jsx";
import CarFormCheckbox from "./CarFormCheckbox.jsx";
import Notifications, { notify } from 'react-notify-toast';
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CarFormRadio from "./CarFormRadio.jsx";
import Badge from 'components/Badge/Badge.jsx';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  uploadButton: {
    backgroundColor: "#1565c0",
    color: "white",
    padding: 8,
    borderRadius: 4,
    cursor: "pointer",
    justifyContent: "center"
  }
};

class CarForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      buttonText: "",
      files: [],
      status: "",
      filename: "",
      progress: 0,
      isUploading: false,
      submited: false,
      car: {
        name: "",
        fuel: "",
        status: "",
        statusColor: "primary",
        motor: "",
        consumption: "",
        color: "",
        price: "",
        year: 0,
        equipment: "",
        buy: false,
        show: []
      }
    }
  }

  componentDidMount() {
    if (this.props.history.location.state) {
      this.setState({
        buttonText: "Uložit",
        status: "edit",
        title: "Upravit auto",
        filename: this.props.history.location.state
      });
      this.props.firebase.getCar(this.props.history.location.state)
        .then(c => {
          this.setState({ car: c.data() });
        })
        .catch(err => {
          notify.show("Chyba databáze", "error");
        });
    } else {
      this.setState({
        buttonText: "Přidat",
        status: "add",
        title: "Přidat auto"
      });
    }
  }

  componentWillUnmount() {

  }

  addCar() {
    const { car } = this.state;
    this.props.firebase.createCar(car)
      .then(ref => {
        this.setState({ filename: ref.id, submited: true })
        this.startManualUpload();
        notify.show("Auto úspěšně přidáno", "success");
      })
      .catch(err => {
        notify.show("Chyba při přidávání", "error");
      });
  }

  editCar() {
    const { car } = this.state;
    this.props.firebase.updateCar(car, this.props.history.location.state)
      .then(success => {
        this.setState({ submited: true });
        if (this.state.files.length > 0) {
          notify.show("Auto úspěšně upraveno", "success");
          this.startManualUpload();
        } else {
          notify.show("Auto úspěšně upraveno", "success");
          setTimeout(() => {
            this.props.history.push("cars");
          }, 2000);
        }
      })
      .catch(err => {
        notify.show("Chyba při ukládání", "error");
      });
  }

  handleToggle(value) {
    const { car } = this.state;
    const currentIndex = car.show.indexOf(value);
    const newShow = [...car.show];

    if (currentIndex === -1) {
      newShow.push(value);
    } else {
      newShow.splice(currentIndex, 1);
    }

    this.setState({
      car: { ...this.state.car, show: newShow }
    });
  }

  customOnChangeHandler = (event) => {
    const { target: { files } } = event;
    const filesToStore = [];
    [...files].forEach(file => filesToStore.push(file));
    fixRotation(filesToStore);
    this.setState({ files: filesToStore });
  }

  startManualUpload() {
    const { files } = this.state;
    files.forEach(file => {
      this.fileUploader.startUpload(file)
    });
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    notify.show("Chyba při nahrávání obrázku", "error");
  };
  handleUploadSuccess = (uploadedName, obj) => {
    const { filename } = this.state;
    this.setState({ progress: 100, isUploading: false });
    this.props.firebase.getURL(uploadedName)
      .then(url => {
        this.props.firebase.setCarImage(url, uploadedName, filename);
      });
    this.props.history.push("cars");
  };

  onSubmit = e => {
    if (this.state.status === "edit") {
      this.editCar();
    }
    else if (this.state.status === "add") {
      this.addCar();
    }
    e.preventDefault();
  }

  onChange = e => {
    this.setState({
      car: { ...this.state.car, [e.target.name]: e.target.value }
    });
  };

  handleSwitchBUY = name => e => {
    this.setState({ car: { ...this.state.car, [name]: e.target.checked } });
  }

  handleChangeRadio = event => {
    this.setState({ car: { ...this.state.car, statusColor: event.target.value } });
  };

  render() {
    const { classes } = this.props;
    const { car, buttonText, title, filename, progress, submited } = this.state;
    const isInvalid =
      car.name === ""
      || car.fuel === ""
      || car.motor === ""
      || car.consumption === ""
      || car.color === ""
      || car.price === ""
      || car.year === 0;
    return (
      <div>
        <Notifications />
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <form onSubmit={this.onSubmit}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>{title}</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.car.buy}
                            onChange={this.handleSwitchBUY("buy")}
                            value="buy"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              track: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Auto do odkupu"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Název auta"
                        id="name"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "name",
                          value: car.name,
                          onChange: this.onChange
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Rok výroby"
                        id="year"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          name: "year",
                          value: car.year,
                          onChange: this.onChange
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Palivo"
                        id="fuel"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "fuel",
                          value: car.fuel,
                          onChange: this.onChange
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Status"
                        id="status"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "status",
                          value: car.status,
                          onChange: this.onChange
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <h4>Barva a náhled statusu:</h4>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CarFormRadio
                        onChange={this.handleChangeRadio}
                        checkedPrimary={car.statusColor === "primary"}
                        checkedRed={car.statusColor === "danger"}
                        checkedOrange={car.statusColor === "warning"}
                        checkedGreen={car.statusColor === "success"}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <Badge color={car.statusColor}>{car.status}</Badge>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Motor"
                        id="motor"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "motor",
                          value: car.motor,
                          onChange: this.onChange
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Barva"
                        id="color"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "color",
                          value: car.color,
                          onChange: this.onChange
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Spotřeba"
                        id="consumption"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "consumption",
                          value: car.consumption,
                          onChange: this.onChange
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Výbava"
                        id="equipment"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "equipment",
                          value: car.equipment,
                          onChange: this.onChange
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Cena"
                        id="price"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          name: "price",
                          value: car.price,
                          onChange: this.onChange
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <h4>Zobrazit na:</h4>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CarFormCheckbox
                        title="Sokol Group"
                        onClick={() => this.handleToggle("sokolgroup")}
                        checked={car.show.indexOf("sokolgroup") !== -1 ? true : false}
                      />
                      <CarFormCheckbox
                        title="Uber Rent"
                        onClick={() => this.handleToggle("uberrent")}
                        checked={car.show.indexOf("uberrent") !== -1 ? true : false}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CarFormCheckbox
                        title="PSTaxi"
                        onClick={() => this.handleToggle("pstaxi")}
                        checked={car.show.indexOf("pstaxi") !== -1 ? true : false}
                      />
                      <CarFormCheckbox
                        title="Auto Sokol"
                        onClick={() => this.handleToggle("autosokol")}
                        checked={car.show.indexOf("autosokol") !== -1 ? true : false}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <label>Nahrát obrázek: </label>
                      <FileUploader
                        accept="image/*"
                        name="image"
                        filename={filename}
                        storageRef={this.props.firebase.storageRef()}
                        onChange={this.customOnChangeHandler}
                        ref={instance => { this.fileUploader = instance; }}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                        className={classes.uploadButton}
                      />
                      <h4>Průběh nahrávání: {progress}%</h4>
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={progress}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={isInvalid || submited}
                  >
                    {buttonText}
                  </Button>
                  <Button color="danger" onClick={() => this.props.history.push("cars")}>Zrušit</Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

CarForm.propTypes = {
  classes: PropTypes.object
};

export default withFirebase(withStyles(styles)(CarForm));
