// @material-ui/icons
import Car from "@material-ui/icons/DirectionsCar";
//import Person from "@material-ui/icons/Person";

// core components/views for Admin layout
//import CarForm from "views/Admin/views/CarForm/CarForm.jsx";
import Cars from "views/Admin/views/CarsPage/CarsPage.jsx";

const dashboardRoutes = [
  {
    path: "/cars",
    name: "Auta",
    icon: Car,
    component: Cars,
    layout: "/admin"
  },
  // {
  //   path: "/carform",
  //   name: "CarFormTest",
  //   icon: Person,
  //   component: CarForm,
  //   layout: "/admin"
  // }
];

export default dashboardRoutes;
