import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { AuthUserContext, withAuthorization } from 'components/Session';
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "components/Admin/Navbars/Navbar.jsx";
import Sidebar from "components/Admin/Sidebar/Sidebar.jsx";

import routes from "../routes.js";
import CarForm from "views/Admin/views/CarForm/CarForm.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar.jpg";
import logo from "assets/img/reactlogo.png";

let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Route path="/admin/carform" component={CarForm} />
    <Redirect from="/admin" to="/admin/cars" />
  </Switch>
);

class Dashboard extends React.Component {
  state = {
    image: image,
    color: "blue",
    hasImage: true,
    fixedClasses: "dropdown show",
    mobileOpen: false,
    user: {}
  };
  signOut = () => {
    this.props.firebase.doSignOut();
  }
  mainPanel = React.createRef();
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return window.location.pathname !== "/admin/maps";
  }
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.mainPanel.current.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className={classes.wrapper}>
            <Sidebar
              routes={routes}
              logoText={"Administrace"}
              loggedUserText={authUser.displayName}
              logo={logo}
              image={this.state.image}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color={this.state.color}
              onSignOut={this.signOut}
              handleHomeClick={() => this.props.history.push("/")}
              {...rest}
            />
            <div className={classes.mainPanel} ref={this.mainPanel}>
              <Navbar
                routes={routes}
                handleDrawerToggle={this.handleDrawerToggle}
                onSignOut={this.signOut}
                handleHomeClick={() => this.props.history.push("/")}
                {...rest}
              />
              {this.getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>{switchRoutes}</div>
                </div>
              ) : (
                  <div className={classes.map}>{switchRoutes}</div>
                )}
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withStyles(dashboardStyle)(Dashboard));
