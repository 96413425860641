import app from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import "firebase/firebase-storage";
import "firebase/performance";

const config = {
  apiKey: "AIzaSyBOSZoNv_bGcCkco-w_Nwvqx71e4Q92Plw",
  authDomain: "sokolgroup-42c93.firebaseapp.com",
  databaseURL: "https://sokolgroup-42c93.firebaseio.com",
  projectId: "sokolgroup-42c93",
  storageBucket: "sokolgroup-42c93.appspot.com",
  messagingSenderId: "317722713138",
  appId: "1:317722713138:web:b3bd8ff7ff462de5"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.perf = app.performance();
  }

  // *** Auth API ***

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  // *** DB API ***

  createCar = (car) =>
    this.db.collection('cars').add({
      name: car.name,
      fuel: car.fuel,
      status: car.status,
      statusColor: car.statusColor,
      motor: car.motor,
      consumption: car.consumption,
      color: car.color,
      price: car.price,
      year: car.year,
      equipment: car.equipment,
      buy: car.buy,
      show: car.show,
      image: {
        url: "",
        name: ""
      }
    });

  updateCar = (car, id) =>
    this.db.collection('cars').doc(id).update({
      name: car.name,
      fuel: car.fuel,
      status: car.status,
      statusColor: car.statusColor,
      motor: car.motor,
      consumption: car.consumption,
      color: car.color,
      price: car.price,
      year: car.year,
      equipment: car.equipment,
      buy: car.buy,
      show: car.show
    });

  setCarImage = (url, img, id) => this.db.collection('cars').doc(id).update({
    image: {
      url: url,
      name: img
    }
  });

  deleteCar = (id) => this.db.collection('cars').doc(id).delete();

  getCar = (id) => this.db.collection('cars').doc(id).get();

  getAllCars = () => this.db.collection('cars').get();

  allCarsRef = () => this.db.collection('cars');

  // *** Storage API ***

  storageRef = () => this.storage.ref("cars");

  //storageRefByCarId = (name) => this.storageRef.child(name);

  deleteCarImg = (name) => this.storage.ref("cars").child(name).delete();

  getURL = (name) => this.storage.ref("cars").child(name).getDownloadURL();

}

export default Firebase;

