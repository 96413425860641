import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//import Button from "components/CustomButtons/Button.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ContactSection extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.section}>
                <h2 className={classes.title}>Kontakt</h2>
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                            <InfoArea
                                title="773 130 073"
                                description=""
                                icon={PhoneIcon}
                                iconColor="primary"
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <InfoArea
                                title="777 221 924"
                                description="Zavolejte nám nebo napište email."
                                icon={PhoneIcon}
                                iconColor="rose"
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <InfoArea
                                title="taxipujcovna@seznam.cz"
                                description=""
                                icon={EmailIcon}
                                iconColor="success"
                            />
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

ContactSection.propTypes = {
    classes: PropTypes.object
};

export default withStyles(productStyle)(ContactSection);
