import React from "react";
import { withFirebase } from "components/Firebase";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
//import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
// core components
import GridItem from "components/Admin/Grid/GridItem.jsx";
import GridContainer from "components/Admin/Grid/GridContainer.jsx";
import Button from "components/Admin/CustomButtons/Button.jsx";
import Notifications, { notify } from 'react-notify-toast';
import Loading from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import AdminCarCard from "components/Card/AdminCarCard.jsx";

class CarsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cars: [],
            open: false,
            carName: "",
            carId: "",
            carImg: "",
            loading: true,
        }
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getAllCars();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate() {

    }

    async getAllCars() {
        if (this._isMounted) {
            this.setState({ cars: [] })
        }
        await this.props.firebase.getAllCars()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let data = doc.data();
                    data.id = doc.id;
                    this.setState({
                        cars: [...this.state.cars, data]
                    })
                });
                this.setState({ loading: false })
            })
            .catch(err => {
                notify.show("Chyba databáze", "error");
            });
    }

    deleteCar(id, img) {
        this.props.firebase.deleteCar(id)
            .then(success => {
                this.props.firebase.deleteCarImg(img);
                this.setState({ open: false });
                notify.show("Auto úspěšně odstraněno", "success");
                this.getAllCars();
            })
            .catch(err => {
                notify.show("Chyba při odstranění", "error");
            });
    }

    handleClose = () => {
        this.setState({
            open: false,
            carName: "",
            carId: "",
            carImg: ""
        });
    }

    handleOpen(id, img, name) {
        this.setState({
            open: true,
            carName: name,
            carId: id,
            carImg: img
        });
    }

    render() {
        const { cars, open, carName, carId, carImg, loading } = this.state;
        return !loading ? (
            <div>
                <Notifications />
                <Button color="white" onClick={() => this.props.history.push("carform")}>Přidat auto</Button>
                <GridContainer>
                    {cars.map((car, i) => {
                        return (
                            <Grow in={true} key={i}>
                                <GridItem xs={12} sm={6} md={4} lg={3}>
                                    <AdminCarCard
                                        cardTitle={car.name}
                                        cardImage={car.image.url}
                                        cardImageTitle={car.name}
                                        cardContent={
                                            <div>
                                                <p>Název: <strong>{car.name}</strong></p>
                                                {car.status ? <p>Status: <strong>{car.status}</strong></p> : null}
                                                <p>Cena: <strong>{car.price}</strong></p>
                                                <p>Rok: <strong>{car.year}</strong></p>
                                                <p>Zobrazeno na: <strong>{car.show + ""}</strong></p>
                                                {car.buy ? <p><strong>Auto určeno do odkupu</strong></p> : null}
                                            </div>
                                        }
                                        editClick={() => this.props.history.push({ pathname: "carform", state: car.id })}
                                        deleteClick={() => this.handleOpen(car.id, car.image.name, car.name)}
                                    />
                                </GridItem>
                            </Grow>
                        )
                    })}
                </GridContainer>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">Odstranění vozidla</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Opravdu chcete odstranit {carName} ?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.deleteCar(carId, carImg)} color="danger">
                            Odstranit
                        </Button>
                        <Button onClick={this.handleClose} color="warning" autoFocus>
                            Zrušit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        ) : <div><Loading color="warning" /></div>;
    }
}

CarsPage.propTypes = {
    classes: PropTypes.object
};

export default withFirebase(CarsPage);