import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import styles from "assets/jss/material-kit-react/customCheckboxRadioSwitch.jsx";

class CarFormRadio extends React.Component {
    render() {
        const { classes } = this.props;
        const wrapperDiv = classNames(
            classes.checkboxAndRadio,
            classes.checkboxAndRadioHorizontal
        );
        return (
            <div>
                <div className={wrapperDiv}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={this.props.checkedPrimary}
                                onChange={this.props.onChange}
                                value="primary"
                                name="radioC"
                                aria-label="Modrá"
                                icon={
                                    <FiberManualRecord
                                        className={classes.radioUnchecked}
                                    />
                                }
                                checkedIcon={
                                    <FiberManualRecord className={classes.radioChecked} />
                                }
                                classes={{
                                    checked: classes.radio
                                }}
                            />
                        }
                        classes={{
                            label: classes.label
                        }}
                        label="Modrá"
                    />
                </div>
                <div className={wrapperDiv}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={this.props.checkedRed}
                                onChange={this.props.onChange}
                                value="danger"
                                name="radioC"
                                aria-label="Červená"
                                icon={
                                    <FiberManualRecord
                                        className={classes.radioUnchecked}
                                    />
                                }
                                checkedIcon={
                                    <FiberManualRecord className={classes.radioChecked} />
                                }
                                classes={{
                                    checked: classes.radio
                                }}
                            />
                        }
                        classes={{
                            label: classes.label
                        }}
                        label="Červená"
                    />
                </div>
                <div className={wrapperDiv}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={this.props.checkedOrange}
                                onChange={this.props.onChange}
                                value="warning"
                                name="radioC"
                                aria-label="Oranžová"
                                icon={
                                    <FiberManualRecord
                                        className={classes.radioUnchecked}
                                    />
                                }
                                checkedIcon={
                                    <FiberManualRecord className={classes.radioChecked} />
                                }
                                classes={{
                                    checked: classes.radio
                                }}
                            />
                        }
                        classes={{
                            label: classes.label
                        }}
                        label="Oranžová"
                    />
                </div>
                <div className={wrapperDiv}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={this.props.checkedGreen}
                                onChange={this.props.onChange}
                                value="success"
                                name="radioC"
                                aria-label="Zelená"
                                icon={
                                    <FiberManualRecord
                                        className={classes.radioUnchecked}
                                    />
                                }
                                checkedIcon={
                                    <FiberManualRecord className={classes.radioChecked} />
                                }
                                classes={{
                                    checked: classes.radio
                                }}
                            />
                        }
                        classes={{
                            label: classes.label
                        }}
                        label="Zelená"
                    />
                </div>
            </div>
        )
    }
}

CarFormRadio.propTypes = {
    classes: PropTypes.object.isRequired,
    checkedPrimary: PropTypes.bool,
    checkedRed: PropTypes.bool,
    checkedOrange: PropTypes.bool,
    checkedGreen: PropTypes.bool,
    onChange: PropTypes.func
};

export default withStyles(styles)(CarFormRadio);