import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from 'clsx';
import Button from "components/CustomButtons/Button.jsx";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {
    card: {
        maxWidth: 345,
        marginTop: 30
    },
    media: {
        height: 0,
        paddingTop: '70%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
};

class CarCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }
    }

    handleExpandClick = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const { classes } = this.props;
        const { expanded } = this.state;
        return (
            <Card className={classes.card} raised>
                <CardHeader
                    title={this.props.cardTitle}
                />
                <CardMedia
                    className={classes.media}
                    image={this.props.cardImage}
                    title={this.props.cardImageTitle}
                />
                <CardContent>
                    {this.props.cardContent}
                </CardContent>
                <CardActions disableSpacing>
                    <Button color="primary" simple onClick={this.handleExpandClick}>Informace o vozidle</Button>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="Zobrazit informace"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Typography>Informace o vozidle:</Typography>
                    <CardContent>
                        {this.props.cardContentExpanded}
                    </CardContent>
                </Collapse>
            </Card>
        );
    }
}

CarCard.propTypes = {
    classes: PropTypes.object,
    cardImage: PropTypes.string,
    cardImageTitle: PropTypes.string,
    cardTitle: PropTypes.object,
    cardContent: PropTypes.object,
    cardContentExpanded: PropTypes.object
};

export default withStyles(styles)(CarCard);
