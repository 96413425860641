import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

const styles = {
    card: {
        maxWidth: 345,
        marginTop: 30
    },
    media: {
        height: 0,
        paddingTop: '70%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
};

class AdminCarCard extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Card className={classes.card} raised>
                <CardHeader
                    title={this.props.cardTitle}
                />
                <CardMedia
                    className={classes.media}
                    image={this.props.cardImage}
                    title={this.props.cardImageTitle}
                />
                <CardContent>
                    {this.props.cardContent}
                </CardContent>
                <CardActions disableSpacing>
                    <Button color="primary" onClick={this.props.editClick}>Upravit</Button>
                    <Button color="danger" onClick={this.props.deleteClick}>Odstranit</Button>
                </CardActions>
            </Card>
        );
    }
}

AdminCarCard.propTypes = {
    classes: PropTypes.object,
    cardImage: PropTypes.string,
    cardImageTitle: PropTypes.string,
    cardTitle: PropTypes.string,
    cardContent: PropTypes.object,
    editClick: PropTypes.func,
    deleteClick: PropTypes.func
};

export default withStyles(styles)(AdminCarCard);
