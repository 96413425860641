import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { withAuthentication } from "components/Session";

import "assets/scss/material-kit-react.scss?v=1.7.0";

// pages for this product
import HomePage from "views/HomePage/HomePage.jsx";
import LoginPage from "views/LoginPage/LoginPage.jsx";
import Admin from "views/Admin/layouts/Admin.jsx";

let hist = createBrowserHistory();

class App extends React.Component {
    render() {
        return (
            <Router history={hist} >
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/admin" component={Admin} />
                </Switch>
            </Router>
        );
    }
}

export default withAuthentication(App);
