import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.jsx";
//import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";

function Header({ ...props }) {
  // function makeBrand() {
  //   var name;
  //   props.routes.map(prop => {
  //     if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
  //       name = props.rtlActive ? prop.rtlName : prop.name;
  //     }
  //     return null;
  //   });
  //   return name;
  // }
  const { classes, color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          {/* <Button color="white" href="#" className={classes.title} disabled>
            {makeBrand()}
          </Button> */}
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks handleSignOut={props.onSignOut} homeClick={props.handleHomeClick}/>
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            style={{ color: "white" }}
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  onSignOut: PropTypes.func,
  handleHomeClick: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(headerStyle)(Header);
