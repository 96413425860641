import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withFirebase } from "components/Firebase";
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/core
import Grow from '@material-ui/core/Grow';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Admin/Table/Table.jsx";
import Loader from "@material-ui/core/LinearProgress";
import CarCard from "components/Card/CarCard.jsx";
import Badge from 'components/Badge/Badge.jsx';

import ArrowIconUp from "@material-ui/icons/ArrowDropUp";
import ArrowIconDown from "@material-ui/icons/ArrowDropDown";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

class OfferSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cars: [],
      limit: 3,
      loading: true,
      visibile: true
    }
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.getAllCars();
  }

  componentDidUpdate(prevProps, prevState) {
    const newLimit = this.state.limit !== prevState.limit
    if (newLimit) {
      this.getAllCars();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getAllCars() {
    if (this._isMounted) {
      this.setState({ cars: [] })
    }

    const { limit } = this.state;

    await this.props.firebase.allCarsRef().where("show", "array-contains", "sokolgroup").orderBy("year", "desc").limit(limit).get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          this.setState({
            cars: [...this.state.cars, doc.data()],
          })
        });
        this.setState({ loading: false })
      })
      .catch(err => {
        console.log(err)
      });
  }

  showMore = () => {
    this.setState({
      visibile: false,
      loading: true,
      limit: 100
    })
  }
  showLess = () => {
    this.setState({
      visibile: true,
      loading: true,
      limit: 3
    })
  }

  render() {
    const { classes } = this.props;
    const { cars, loading, visibile } = this.state;
    return (
      <div className={classes.section}>
        <h2 className={classes.title}>Žhavá nabídka</h2>
        {loading ? <div><br /><Loader /><br /></div> : null}
        <div>
          <GridContainer>
            {cars.map((car, i) => {
              return car.show.indexOf("sokolgroup") > -1 && !loading ? (
                <Grow in={true} key={i}>
                  <GridItem xs={12} sm={12} md={4}>
                    <CarCard
                      cardTitle={
                        <div>
                          <h3 className={classes.title} style={{ margin: 0 }}>{car.name}</h3>
                          {car.status ? <Badge color={car.statusColor}>{car.status}</Badge> : null}
                        </div>
                      }
                      cardImage={car.image.url}
                      cardImageTitle={car.name}
                      cardContent={
                        !car.buy ?
                          <div className={classes.textCenter}>
                            <hr />
                            <h3 style={{ color: "red" }}><strong>Za: {car.price}</strong></h3>
                          </div>
                          :
                          <div className={classes.textCenter}>
                            <hr />
                            <h3>{car.price}</h3>
                          </div>
                      }
                      cardContentExpanded={
                        <Table
                          tableData={[
                            ["Rok výroby:", car.year],
                            ["Palivo:", car.fuel],
                            ["Motor:", car.motor],
                            ["Barva:", car.color],
                            ["Spotřeba:", car.consumption],
                            ["Výbava:", car.equipment]
                          ]}
                        />
                      }
                    />
                  </GridItem>
                </Grow>
              ) : null;
            })}
          </GridContainer>
          <br />
        </div>
        {visibile ?
          <Button color="primary" round onClick={this.showMore}><ArrowIconDown />Zobrazit více</Button>
          :
          <Button color="primary" round onClick={this.showLess}><ArrowIconUp />Zobrazit méně</Button>
        }
      </div>
    );
  }
}

OfferSection.propTypes = {
  classes: PropTypes.object
};

export default withFirebase(withStyles(teamStyle)(OfferSection));
